
import { useState, useEffect } from 'react';
let host = "https://api-dev.librairielalinotte.fr";
//host = "http://localhost:8900";

function InstagramFetcher( index, count){
    let url = host + '/fetchInstagram';
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    index:index,
                    count:count
                }
            ),
        };
        //console.log(requestOptions);

        fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                console.log("RESPONSE");
                console.log(data);
                resolve(data);
                //setResp(data);
            })
            .catch(err => {
                console.log(err.message);
                reject();
            });
    });

}


export default InstagramFetcher;