import React, {useState} from 'react';


function BookEditorComponent({book, id}){

    function handleChange(evt){
        let infoChange = evt.target.classList[0].split("_")[1];
        if(infoChange === "title"){
            setTitle(evt.target.value);
            book.title = evt.target.value;
        }else if(infoChange === "author"){
            setAuthor(evt.target.value);
            book.author = evt.target.value;
        }else if(infoChange === "poster"){
            setPoster(evt.target.value);
            book.poster = evt.target.value;
        }else if(infoChange === "type"){
            setType(evt.target.value);
            book.type = evt.target.value;
        }else if(infoChange === "summary"){
            setSummary(evt.target.value);
            book.summary = evt.target.value;
        }else if(infoChange === "opinion"){
            setOpinion(evt.target.value);
            book.opinion = evt.target.value;
        }else if(infoChange === "opinionAuthor"){
            setOpinionAuthor(evt.target.value);
            book.opinionAuthor = evt.target.value;
        }else if(infoChange === "price"){
            setPrice(evt.target.value);
            book.price = evt.target.value;
        }else if(infoChange === "isNew"){
            setIsNew(!isNew);
            book.isNew = !isNew;
        }else if(infoChange === "isHeart"){
            setIsHeart(!isHeart);
            book.isHeart = !isHeart;
        }
    }

    //let poster_link = "pagnol.jpg";
    const [title, setTitle] = useState(book.title);
    const [author, setAuthor] = useState(book.author);
    const [poster, setPoster] = useState(book.poster);
    const [type, setType] = useState(book.type);
    const [summary, setSummary] = useState(book.summary);
    const [opinion, setOpinion] = useState(book.opinion);
    const [opinionAuthor, setOpinionAuthor] = useState(book.opinionAuthor);
    const [price, setPrice] = useState(book.price);
    const [isNew, setIsNew] = useState(book.isNew);
    const [isHeart, setIsHeart] = useState(book.isHeart);

    return <>
        <div id={id} className="td book book-title">
            <div className="flex inputelement riot-font" ><p className={"inputelement"}>Title : </p><input className="editor_title" onChange={handleChange} value={title}/></div>
            <div className="flex inputelement riot-font"><p className={"inputelement"}>Author : </p><input className="editor_author" onChange={handleChange} value={author} /></div>
            <div className="flex inputelement riot-font"><p className={"inputelement"}>Poster source: </p><input className="editor_poster" onChange={handleChange} value={poster} /></div>
            <img className="book-cover" src={`./imgs/books/${poster}`} alt=""/>
            <div className="flex inputelement riot-font"><p className={"inputelement"}>Type : </p><input className="editor_type" onChange={handleChange} value={type}/></div>
            <div className="flex inputelement riot-font "><p className={"inputelement"}>Summary : </p><textarea className="textarea editor_summary" onChange={handleChange} value={summary}/></div>
            <div className="flex inputelement riot-font "><p className={"inputelement"}>Opinion : </p><textarea className="textarea editor_opinion" onChange={handleChange} value={opinion} size="4"/></div>
            <div className="flex inputelement riot-font "><p className={"inputelement"}>Opinion Author : </p><input className="editor_opinionAuthor" onChange={handleChange} value={opinionAuthor}/></div>
            <div className="flex inputelement riot-font "><p className={"inputelement"}>Price : </p><input className="editor_price" onChange={handleChange} value={price}/></div>
            <div className="flex inputelement riot-font "><p className={"inputelement"}>Nouveauté : </p><input className="editor_isNew" onChange={handleChange} type="checkbox" defaultChecked={isNew}/></div>
            <div className="flex inputelement riot-font "><p className={"inputelement"}>CoupDeCoeur : </p><input className="editor_isHeart" onChange={handleChange} type="checkbox" defaultChecked={isHeart}/></div>
        </div>
    </>;

}

export default BookEditorComponent;