import logo from './data/imgs/La Linotte - Bannière finale.png';
import phone from './data/icons/phone.svg';
import edit from './data/icons/edit.svg';
import calendar from './data/icons/calendar.svg';
import facebook from './data/icons/facebook.svg';
import instagram from './data/icons/instagram.svg';
import './App.css';

import React, {useState} from 'react';
import Actualites from "./model/Actualites";
import Nouveautes from "./model/Nouveautes";
import CoupsDeCoeurs from "./model/CoupsDeCoeurs";
import Contact from "./model/Contact";
import BookEditor from "./model/BookEditor";

import Cookies from 'js-cookie';
import Login from "./util/Login";
import TestToken from "./util/TestToken";

let currentContent = "Actualites";

let cdcs = [];
let nvts = [];
let init = false;
let logged_in = false;
let PORT = 8900;

function App() {

    const token = Cookies.get('token');
    if(logged_in === false && token){
        TestToken('https://api-dev.librairielalinotte.fr' + '/testToken', token).then(r => {
            if(r['message'] === 'oui'){
                console.log('logged in');
            }
        });
        logged_in = true;
    }

    if (init === false) {
        init = true;
        window.login = manageThing;
        /*
        Requester('http://localhost:3001/api/book/get/cdc').then(bookResp => {
            if (bookResp) {
                console.log(bookResp);
                console.log(bookResp.length);
                let list_books = [];
                for (let i = 0; i < bookResp.length; i++) {
                    let book = new BookModel().fromJson(bookResp[i]);
                    list_books.push(<BookComponent book={book}></BookComponent>);
                }
                cdcs = list_books;
            }
        }).catch(rejectMessage => {
            console.log("Failed to fetch cdc");
        });
        Requester('http://localhost:3001/api/book/get/nouveaute').then(bookResp => {
            console.log(bookResp);
            console.log(bookResp.length);
            if (bookResp) {
                let list_books = [];
                for (let i = 0; i < bookResp.length; i++) {
                    let book = new BookModel().fromJson(bookResp[i]);
                    list_books.push(<BookComponent book={book}></BookComponent>);
                }
                nvts = list_books;
            }
        }).catch(rejectMessage => {
            console.log("Failed to fetch nouveauté");
        });
         */
    }

    function getSectionContent(sectionContentId) {
        if (sectionContentId === "Actualites") {
            return <>
                <Actualites/>
            </>;
        } else if (sectionContentId === "Nouveautes") {
            return <>
                <Nouveautes books={nvts}/>
            </>

        } else if (sectionContentId === "CoupsDeCoeur") {
            return <>
                <CoupsDeCoeurs books={cdcs}/>
            </>
        } else if (sectionContentId === "Contact") {
            return <>
                <Contact/>
            </>
        } else if (sectionContentId === "BookEditor") {
            return <>
                <BookEditor/>
            </>
        }

    }

    function goHome(){
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }

    function clickMenuElement(evt, menuElementId) {
        if (menuElementId !== currentContent) {
            console.log(evt);
            currentContent = menuElementId;
            setSectionContent(getSectionContent(currentContent));
        }
    }

    const [displayExpandMenu, setDisplayExpandMenu] = useState(false);
    const footerContent =
        <footer className={"footer"}>
            <p></p>
            <div className={"addresse"}>
                <p>1 place de la Libération - 63120 Courpière</p>
                <p>Mail : <a href={"mailto:contact@librairielalinotte.com"}>contact@librairielalinotte.com</a></p>
                <p>Tel : 09 55 84 55 18</p>
            </div>
            <p>
                {/*<p id="texte_me_contacter" className="riot-font invisible">Pour me contacter, y'a plein de façons</p>*/}
                <p id="texte_me_contacter" className="riot-font invisible"></p>
            </p>
            <div className={"reseaux"}>
                <p>
                    <li className={"reseau flex"}>
                        <img className="inline-block icon white" src={facebook} alt=""/>
                        <div className={"inline-block"}><a href={"https://facebook.com/librairielalinotte"} target="_blank" rel="noopener noreferrer">Facebook</a></div>
                    </li>
                </p>
                <p>
                    <li className={"reseau flex"}>
                        <img className="inline-block icon white" src={instagram} alt=""/>
                        <div className={"inline-block"}><a href={"https://instagram.com/librairielalinotte/"} target="_blank" rel="noopener noreferrer">Instagram</a></div>
                    </li>
                </p>
                {/*
            <p>
                <li className={"reseau flex"}>
                    <img className="inline-block icon white" src={tiktok} alt=""/>
                    <div className={"inline-block"}><a href={"https://tiktok.com"}>TikTok</a></div>
                </li>
            </p>
            */}
            </div>
            <p></p>
        </footer>;
    const [sectionContent, setSectionContent] = useState(getSectionContent(currentContent));

    return (
        <div className="App">
            <header onClick={goHome} className="App-header global-bg cursor">
                <img src={logo} className="App-logo" alt="logo"/>
            </header>
            <div className="container">

                <section className="menu" onMouseEnter={() => setDisplayExpandMenu(true)}
                         onMouseLeave={() => setDisplayExpandMenu(false)}>

                    <ul className="left-menu">
                        <li className="menu-element"
                            id="Actualites"
                            onClick={(event) => clickMenuElement(event, "Actualites")}
                        >
                            <img className="inline-block icon" src={calendar} alt=""/>
                            <div className="inline-block"
                                 style={{display: displayExpandMenu ? '' : 'none'}}>Actualités
                            </div>
                        </li>
                        {/*}
                        <li className="menu-element"
                            id="Nouveautes"
                            onClick={(event) => clickMenuElement(event, "Nouveautes")}
                        >
                            <img className="inline-block icon" src={book} alt=""/>
                            <div className="inline-block"
                                 style={{display: displayExpandMenu ? '' : 'none'}}>Nouveautés
                            </div>
                        </li>

                        <li className="menu-element"
                            id="CoupsDeCoeur"
                            onClick={(event) => clickMenuElement(event, "CoupsDeCoeur")}
                        >
                            <img className="inline-block icon" src={heart} alt=""/>
                            <div className="inline-block" style={{display: displayExpandMenu ? '' : 'none'}}>Coups de
                                coeur
                            </div>
                        </li>
                        */}
                        <li className="menu-element"
                            id="Contact"
                            onClick={(event) => clickMenuElement(event, "Contact")}
                        >
                            <img className="inline-block icon" src={phone} alt=""/>
                            <div className="inline-block" style={{display: displayExpandMenu ? '' : 'none'}}>Contact
                            </div>
                        </li>
                        {logged_in ?
                        <li className="menu-element"
                            id="BookEditor"
                            onClick={(event) => clickMenuElement(event, "BookEditor")}
                        >
                            <img className="inline-block icon" src={edit} alt=""/>
                            <div className="inline-block"
                                 style={{display: displayExpandMenu ? '' : 'none'}}>BookEditor
                            </div>
                        </li> : <></>
                        }
                        {/*
                    <li className="menu-element"
                        id="Connexion"
                        onClick={(event) => clickMenuElement(event, "Connexion")}
                    >
                        <img className="inline-block icon" src={login} alt=""/>
                        <div className="inline-block" style={{display: displayExpandMenu ? '' : 'none' }}>Connexion</div>
                    </li>
                    */}
                    </ul>
                </section>
                <section className="content">
                    {sectionContent}
                </section>
            </div>
            {footerContent}
        </div>
    );
}

function manageThing(value){
    Login('https://api-dev.librairielalinotte.fr'+'/login', value).then(r => {
        if(r['message'] === 'oui'){
            let v = r['token'];
            Cookies.set("token", v, {
                expires: 7,
            });
        }
    });
    setTimeout(_=>{
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 4000);
}

export default App;
