import React, {useState} from "react";
import MessageSaver from "../util/MessageSaver";
let refreshToastCallback = false;
let PORT = 8900;

function Contact(){

    const putMessage = async () => {
        try {
            let messageObject = {
                "name":name,
                "mail":mail,
                "message":message
            };
            //name, mail, message
            let resp = await MessageSaver('https://api-dev.librairielalinotte.fr'+'/api/message/new', messageObject);
        }catch (error) {
            console.log(error);
        }finally {
            console.log("done");
        }
    }

    function handleChange(evt){
        let infoChange = evt.target.classList[0].split("_")[1];
        console.log(infoChange);
        if(infoChange === "Name") {
            setName(evt.target.value);
        }else if(infoChange === "Mail") {
            setMail(evt.target.value);
        }else if(infoChange === "Message") {
            setMessage(evt.target.value);
        }
    }

    function closeToast(){
        setToast(false);
        console.log(refreshToastCallback);
        if(refreshToastCallback){
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    }
    async function sendMessage() {
        if (!(name === "" || mail === "" || message === "")) {
            let d = String(mail)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            if (d !== null) {
                // Tout est bon, on send le message au backend
                await putMessage();

                setToastContent("Merci de votre message, vous allez maintenant être redirigé vers la page d'accueil");
                setToastButton("Page d'accueil");
                refreshToastCallback = true;
                setToast(true);
                return true;
            }
        }
        setToastContent("Un des champ de texte n'est pas renseigné ou incorrect");
        setToastButton("Ok");
        refreshToastCallback = false;
        setToast(true);
        return false;
    }
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [message, setMessage] = useState("");
    const [toast, setToast] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const [toastButton, setToastButton] = useState("");

    document.getElementsByTagName("body")[0].style.backgroundColor="#f1e8ff";
    let element = document.getElementById("texte_me_contacter");
    if(element.classList.contains("invisible")){
        element.classList.remove("invisible");
    }
    return <>
        <div className={"fond " + (toast ? "" : "none")} ></div>
        <div className={"toastContainer " + (toast ? "" : "none")}>
            <div className={"toast"}>
                <div className={"toastMessage"}>
                    <p>{toastContent}</p>
                    <a onClick={closeToast} className={"buttonElement"}>{toastButton}</a>
                </div>

            </div>
        </div>
        <br/><br/>
        <div className={"about"}>
            <div>
            <div className={"contactElement"}>
                <div className={"horaireTitre riot-font"}>Contacter La Linotte</div>
                <div><input className="editor_Name inputElement" onChange={handleChange} placeholder="Votre nom" value={name}/></div>
                <div><input className={"editor_Mail inputElement"} onChange={handleChange} placeholder="Votre adresse mail" value={mail}/></div>
                <div><textarea className={"editor_Message inputElement messageElement"} onChange={handleChange} placeholder="Votre message" value={message}/></div>
                <a onClick={sendMessage} className={"buttonElement"}>Envoyer</a>

            </div>
            </div>
            {/*<div><img className={"lib-img"} src={logo}/></div>*/}
            <div className={"horaires"}>
                <div className={"horaireConteneur"}>
                    <div className={"horaireTitre riot-font"}>Horaires</div>
                    <div id="table">
                        <div className="tr">
                            <div className="td riot-font">Lundi</div>
                            <div className="td center">Fermé</div>
                        </div>
                        <div className="tr">
                            <div className="td riot-font">Mardi</div>
                            <div className="td center">9h30 - 12h | 15h - 18h30</div>
                        </div>
                        <div className="tr">
                            <div className="td riot-font">Mercredi</div>
                            <div className="td center">9h30 - 12h | 15h - 18h30</div>
                        </div>
                        <div className="tr">
                            <div className="td riot-font">Jeudi</div>
                            <div className="td center">9h30 - 12h | 15h - 18h30</div>
                        </div>
                        <div className="tr">
                            <div className="td riot-font">Vendredi</div>
                            <div className="td center">9h30 - 12h | 15h - 18h30</div>
                        </div>
                        <div className="tr">
                            <div className="td riot-font">Samedi</div>
                            <div className="td center">9h30 - 12h | 15h - 18h</div>
                        </div>
                        <div className="tr">
                            <div className="td riot-font">Dimanche</div>
                            <div className="td center">Fermé</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    <br/><br/>
    </>;

}

export default Contact;