import React, {useState} from 'react';

function Article(articleInfo){
    let id = articleInfo.ids;
    const [text, setText] = useState(articleInfo.description);
    const [date, setDate] = useState(articleInfo.date);
    const [image, setImage] = useState(articleInfo.img);

        return <>
            <div className={"article"}>
                <div className="container">
                    {id % 2 === 1 ?
                        <>
                            <div className="text">
                                <h3>{date}</h3>
                                <h2>{text}</h2>
                            </div>
                            <div className="image">
                                {image === null || image === '' ? <></> : <img className={"articleImage"}  src={require(`../data/imgs/actualites/${image}`)} alt="Image"/>}
                            </div>
                        </>
                        :
                        <>
                    <div className="image">
                        {image === null || image === '' ? <></> : <img className={"articleImage"}  src={require(`../data/imgs/actualites/${image}`)} alt="Image"/>}
                    </div>
                    <div className="text">
                        <h3>{date}</h3>
                        <h2>{text}</h2>
                    </div></>
                    }
                </div>
            </div>
        </>
}

export default Article;