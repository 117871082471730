import BookComponent from "../component/BookComponent";

class BookModel {

    fromJson(json){
        this.id = json['_id'];
        this.title = json['title'];
        this.author = json["author"];
        this.year = json["year"];
        this.poster = json['poster'];
        this.summary = json["summary"];
        this.opinion = json["opinion"];
        this.price = json["price"];
        this.type = json["type"];
        this.opinionAuthor = json["opinionAuthor"];
        this.isNew = json["isNew"];
        this.isHeart = json["isHeart"];
        return this;
    }

    constructor(){
        this.id = null;
        this.title = null;
        this.author = null;
        this.year = null;
        this.poster = null;
        this.summary = null;
        this.opinion = null;
        this.price = null;
        this.type = null;
        this.opinionAuthor = null;
        this.isNew = null;
        this.isHeart = null;
    }
    fromParameters(id, title, author, year, poster, summary, opinion, price, type, opinionAuthor){
        this.id = id;
        this.title = title;
        this.author = author;
        this.year = year;
        this.poster = poster;
        this.summary = summary;
        this.opinion = opinion;
        this.price = price;
        this.type = type;
        this.opinionAuthor = opinionAuthor;
        return this;
    }

}

export default BookModel;