
import React, {useState} from "react";


function Nouveautes({books}){


    document.getElementsByTagName("body")[0].style.backgroundColor="#edffed";
    const [list_books, set_list_books] = useState(books);

    return <>
        <h1 className="riot-font">Dans votre librairie ce mois-ci</h1>
        <br/>
        <div className={"livre-container"}>
            <div className="tr">
                {list_books}
            </div>
            <div className="tr">

            </div>
        </div>
    </>;

}

export default Nouveautes;