
import { useState, useEffect } from 'react';

function MessageSaver(url, jsonData){
    //const [resp, setResp] = useState(null);

    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(jsonData),
        };
        console.log(requestOptions);


        fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                console.log("RESPONSE");
                resolve(data);
                //setResp(data);
            })
            .catch(err => {
                console.log(err.message);
                reject();
            });
    });
    /*
    useEffect(()=>{

    }, [url, jsonData]);
    //});
    return (
        resp
    );

     */
}


export default MessageSaver;