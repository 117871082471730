
import { useState, useEffect } from 'react';
import Cookies from "js-cookie";

function BookSaver(url, jsonData){
    const token = Cookies.get('token');
    if(token !== null) {
        jsonData['token'] = token;
        //const [resp, setResp] = useState(null);

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(jsonData),
            };
            console.log(requestOptions);


            fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    console.log("RESPONSE");
                    resolve(data);
                    //setResp(data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject();
                });
        });
    }
    /*
    useEffect(()=>{

    }, [url, jsonData]);
    //});
    return (
        resp
    );

     */
}


export default BookSaver;